.cv-auth-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .cv-auth-container-title {
    margin-bottom: 1rem;
  }
}
