.movies-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sticky-box {
  position: sticky;
  top: 0;
  overflow: scroll;
  height: 100%;
}
